import React from "react"
import Link from "gatsby-link"
import { navigate } from 'gatsby-link'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Contact(props) {

  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(onSuccess())
      .catch((error) => alert(error))
  }

  const onSuccess = (e) => {
    alert("Thanks for getting in touch, I'll be in contact within the next working day-or-two");
    document.getElementById('contact-form').reset();

  }

  return (
  	<section className="contact">
	  	<div className="container">
	  		<div className="contact__left">
          <h2 data-aos="fade-right">
            How to get in touch
            <small>(contact me)</small>
          </h2>
          <p data-aos="fade-right">The best way to get in touch with me is by the contact form on the page. Mainly because I spent a couple of hours building it and it'd be a damn shame to see it go to waste, but also because it lets me know where the email has come from, and stops it from falling into a spam folder.</p>
          <p data-aos="fade-right">Once you've submitted the form, I should get back to you in a day or two. If you want me to get back in touch via a phone call or another means of contact (carrier pigeon, smoke signals, morse code.. any'll do), just leave your contact details in the message box along with the reason you're getting in touch, and I'll get in touch that way, no problem.</p>
        </div>
        <div className="contact__right">
          <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" id="contact-form" onSubmit={handleSubmit} data-aos="fade-left">
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <div class="form-field-wrapper">
              <label for="frmname">Name*</label>
              <input type="text" name="name" id="frmname" placeholder="Joe Bloggs" required onChange={handleChange}/>
            </div>
            <div class="form-field-wrapper">
              <label for="frmemail">Email*</label>
              <input type="email" name="email" id="frmemail" placeholder="joebloggs@mail.com" required onChange={handleChange}/>
            </div>
            <div class="form-field-wrapper">
              <label for="frmmessafe">Your message</label>
              <textarea name="message" id="frmmessage" placeholder="Message goes here" onChange={handleChange}></textarea>
            </div>
            <button type="submit" className="btn">Send</button>
          </form>
        </div>
	  	</div>
  	</section>
  	)
}

// let myForm = document.getElementById('contact-form');

// if (myForm) {
//   myForm.addEventListener("submit", handleSubmit);
// }

// const handleSubmit = (e) => {
//   e.preventDefault()
//   let formData = new FormData(myForm)
//   fetch('/', {
//     method: 'POST',
//     headers: { "Content-Type": "application/x-www-form-urlencoded" },
//     body: new URLSearchParams(formData).toString()
//   }).then(() => console.log('Form successfully submitted')).catch((error) =>
//     alert(error))
// }