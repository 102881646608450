import React from "react"
import AOS from "aos"
import SEO from "../components/seo"
import HeroSmall from "../components/hero--sm"
import CTA from "../components/cta"
import ContactSection from "../components/contact"
// import CopyLeftImageRight from "../components/copy-left-image-right"

class Contact extends React.Component {
    componentDidMount() {
  		AOS.init({
  			duration: 800,
  			easing: 'easeOutCubic',
        anchorPlacement: 'top-bottom'
  		});
    }

    componentDidUpdate() {
      AOS.refresh();
    }

    render() {
        return(
          <div>
            	<SEO title="Contact" description="Hey, Jase here, and I’d love to work on your next project with you. Please get in touch using the contact form and I'll get back to you in the next couple of days." />
		  		    <HeroSmall heading="📱 Get in touch"/>
              <ContactSection/>
              <CTA/>
		  	  </div>
        )
    }
}

export default Contact